import ConvertTemplate, { mutation as convertTemplateMutation } from '@common/containers/Consignments/mutations/convertTemplate';
import { useUserState } from '@common/context/userContext';
import Alert, { AlertType } from '@components/Alert';
import Button from '@components/Button';
import FeedbackButton from '@components/FeedbackButton';
import Loader from '@components/Loader';
import Wizard from '@components/Wizard';
import WizardStep from '@components/Wizard/WizardStep';
import Config from '@config';
import ConfirmModal, { ConfirmModalRef } from '@containers/Consignments/components/ConfirmModal';
import Section from '@containers/Consignments/components/section';
import CreateOrSaveTemplate, { createOrSaveTemplateMutation } from '@containers/Consignments/mutations/createOrSaveTemplate';
import usePrevious from '@effects/usePrevious';
import useWindowTitle from '@effects/useWindowTitle';
import { useWindowWidth } from '@effects/useWindowWidth';
import { actionTypes, useGlobalState } from '@state';
import { GraphqlError, IDynamicQuestion, IStaticQuestion } from '@typings';
import { CONSIGNMENT_SUBMITTED_MESAGE, CONSIGNMENT_SUBMITTED_TITLE, DESKTOP_WIDTH, TRANSPORTER_DELETION_ERROR_CODE, UNKNOWN_PIC } from '@utils/constants';
import { getField } from '@utils/data-manipulator';
import { ConsignmentStatus, GraphqlErrorType } from '@utils/enum-transformers';
import { SectionName } from '@utils/enums';
import MLALogger from '@utils/logger';
import { canCreateConsignment, isMyConsignment } from '@utils/question-editable';
import { getAllChildQuestions } from '@utils/question-getter';
import { getIsRequired, validate, validateRepeatable, validateTrigger } from '@utils/question-validator';
import { DynamicQuestions, isStaticSection, StaticQuestions } from '@utils/section-mapper';
import { hasFormCheck, validateConsignment } from '@utils/validate-consignment';
import classnames from 'classnames';
import deepmerge from 'deepmerge';
import { isEmpty } from 'lodash';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useHistory, useLocation, useParams } from 'react-router';
import { useMutation, useQuery } from 'relay-hooks';

import { containsDeprecatedForms } from './ConsignmentHelper';
import CreateOrSaveConsignment, { cleanConsignmentPIC, createOrSaveConsignmentMutation } from './mutations/createOrSaveConsignment';
import { ConsignmentEditQueryResponse } from './queries/__generated__/ConsignmentEditQuery.graphql';
import { TemplateEditQueryResponse } from './queries/__generated__/TemplateEditQuery.graphql';
import { ConsignmentEditQuery } from './queries/ConsignmentEdit';

export type TAirtableConsignment = ConsignmentEditQueryResponse['consignment'] | TemplateEditQueryResponse['template'];

const ConsignmentEdit: React.FC = () => {
    const [{ user }] = useUserState();
    const envdAccountId = user?.accountDetails?.id;
    useWindowTitle('Edit Consignment');
    const { id } = useParams<any>();
    const history = useHistory();
    const { data } = useQuery<any>(
        ConsignmentEditQuery,
        { id, envdAccountId },
        {
            fetchPolicy: 'network-only',
        }
    );

    if (data) {
        const { consignment } = data;
        const containsDeprecated = containsDeprecatedForms(consignment);
        //ENVDB-1233: if consignment contains deprecated forms then dont let user edit it
        if (consignment && !containsDeprecated) {
            // ENVDB-896: Making sure that destination pic is not AAAAAAAA
            let cloneConsignment = _.clone(consignment);
            if (consignment?.destination?.pic === UNKNOWN_PIC) {
                cloneConsignment = cleanConsignmentPIC(cloneConsignment, 'destination', '');
            }
            return <ConsignmentEditPage consignment={cloneConsignment} />;
        } else {
            history.replace('/consignments');

            return null;
        }
    } else {
        return <Loader error={''} isLoading pastDelay={false} timedOut={false} retry={() => null} />;
    }
};

export default ConsignmentEdit;

function extractFieldFromData<T>(data: any, staticPendingAnswers: any, field: string, defaultValue?: T): [T | null, boolean] {
    const existingField = getField(field, staticPendingAnswers.answers, defaultValue);

    const existsInPending = !!existingField;
    if (!existsInPending || (String(data?.basedOn).startsWith('CT') && field === 'declaration')) {
        const extracted: T | null = getField<T | null, TAirtableConsignment>(field, data, defaultValue ?? null);
        MLALogger.Log(['extractFieldFromData'], { message: 'Does not exist in pending', data, staticPendingAnswers, field, defaultValue });
        return [extracted, existsInPending];
    }
    MLALogger.Log(['extractFieldFromData'], { message: 'Already exists in pending', data, staticPendingAnswers, field, defaultValue, existingField });
    return [existingField ?? null, existsInPending];
}

const setStaticFieldsInStore = (dispatch: any, consignment: any, staticPendingAnswers: any) => {
    const [forms, formExists] = extractFieldFromData(consignment, staticPendingAnswers, 'forms', []);
    let answers = {};

    if (!formExists) {
        answers = deepmerge(answers, { form: forms?.map((x: any) => x.type) });
    }

    // Save the movement fields to the reducer
    const [origin] = extractFieldFromData(consignment, staticPendingAnswers, 'origin', null);
    answers = deepmerge(answers, { origin });

    const [owner] = extractFieldFromData(consignment, staticPendingAnswers, 'owner', null);
    answers = deepmerge(answers, { owner });

    const [destination] = extractFieldFromData(consignment, staticPendingAnswers, 'destination', null);
    answers = deepmerge(answers, { destination });

    const [consignee] = extractFieldFromData(consignment, staticPendingAnswers, 'consignee', null);
    answers = deepmerge(answers, { consignee });

    // Save the declaration fields to the reducer
    const [declaration] = extractFieldFromData(consignment, staticPendingAnswers, 'declaration', null);
    answers = deepmerge(answers, { declaration });

    // If this is a template, check the name
    const [name] = extractFieldFromData(consignment, staticPendingAnswers, 'name', undefined);
    if (name) {
        // We don't want to send name for consignments, so gate it
        answers = deepmerge(answers, { name });
    }

    MLALogger.Log(['setStaticFieldsInStore'], { consignment, staticPendingAnswers, answers });

    dispatch({ type: actionTypes.staticPendingAnswers.setStatic, payload: { dirty: false, answers } });
};

const setDynamicArrayFieldsInStore = (dispatch: any, answers?: any) => {
    // We want to save subforms so only store answers with a defined index
    const indexedAnswers = answers?.filter((x: any) => x.index !== null) ?? [];

    MLALogger.Log(['setDynamicArrayFieldsInStore'], { answers, indexedAnswers });

    dispatch({ type: actionTypes.dynamicPendingAnswers.setDynamic, payload: indexedAnswers });
};

const useUpdateStore = (consignment: TAirtableConsignment) => {
    const [{ staticPendingAnswers }, dispatch] = useGlobalState();
    const [runOnce, setRunOnce] = useState<boolean>(false);

    const lastUpdatedAt = usePrevious(consignment?.updatedAt);
    const currentLastUpdatedAt = consignment?.updatedAt;

    useEffect(() => {
        if (runOnce === false || lastUpdatedAt !== currentLastUpdatedAt) {
            MLALogger.Log(['useUpdateStore'], consignment);
            // Stash questions for later usage
            dispatch({
                type: actionTypes.meta.setConsignmentMeta,
                payload: {
                    number: consignment?.number,
                    questions: consignment?.questions,
                    createdBy: consignment?.createdBy,
                    status: consignment?.status,
                    isTransporterEdit: false,
                    transporterIndex: 0,
                },
            });

            // Stash original answers since we don't want fat mutations all the time
            dispatch({ type: actionTypes.originalAnswers.setOriginal, payload: consignment?.answers ?? [] });

            // Save ALL static items into the store so we can play around with state and set things to null
            setStaticFieldsInStore(dispatch, consignment, staticPendingAnswers);

            // Save all indexed fields to store so we can mess around with them
            setDynamicArrayFieldsInStore(dispatch, consignment?.answers);

            setRunOnce(true);
        }
    }, [lastUpdatedAt, currentLastUpdatedAt, runOnce, consignment, dispatch, staticPendingAnswers]);

    return lastUpdatedAt;
};

// Lets do a custom state + reducer for now while testing
export const isValidPostSubmitSection = (sectionName: string): boolean =>
    sectionName === SectionName.MOVEMENT.toString() || sectionName === SectionName.LIVESTOCK_DESCRIPTION.toString() || sectionName === SectionName.TRANSPORTER.toString();

//#region Private Components
export const ConsignmentEditPage: React.FC<{ consignment: TAirtableConsignment }> = ({ consignment }) => {
    const history = useHistory();
    const location = useLocation();
    const { section: sectionRoute } = useParams<any>();

    const [consignmentMutate, { loading: consignmentLoading }] = useMutation(createOrSaveConsignmentMutation);
    const [templateMutate, { loading: templateLoading }] = useMutation(createOrSaveTemplateMutation);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    // const sectionRoute = useNavigationParam('section');
    const [convertTemplateMutate] = useMutation(convertTemplateMutation);

    const [{ staticPendingAnswers, dynamicPendingAnswers, originalAnswers, generic, copyErrors }, dispatch] = useGlobalState();
    const [{ user }] = useUserState();
    const envdAccountId = user?.accountDetails?.id;
    useUpdateStore(consignment);

    const routeName = location.pathname;
    const type = routeName.startsWith('/consignments/edit') ? 'CONSIGNMENT' : 'TEMPLATE';

    const [headerX, setHeaderX] = useState<number>(0);
    const windowWidth = useWindowWidth();
    useEffect(() => {
        // Not ideal but what can you do
        const headerElems = document.getElementsByClassName('ConsignmentStateViewHeader--Desktop');
        if (headerElems.length > 0) {
            const headerElem = headerElems.item(0);
            const rect = headerElem?.getBoundingClientRect();
            if (rect) {
                // IE only has 'left', other browsers support 'x'
                const rectLeft = rect?.x ?? rect?.left ?? 0;
                const rectWidth = rect?.width ?? 0;
                setHeaderX(rectLeft + rectWidth);
            }
        }
    }, [windowWidth]);

    const isTabletOrMobile = useMediaQuery({ maxWidth: DESKTOP_WIDTH });
    const isDesktop = !isTabletOrMobile;
    const confirmSubmitRef = useRef<ConfirmModalRef>();
    const saveOnExitRef = useRef<ConfirmModalRef>();
    const convertTemplateRef = useRef<ConfirmModalRef>();
    const [warningMessage, setWarningMessage] = useState<string | undefined>(undefined);
    const [consignmentErrors, setConsignmentErrors] = useState<GraphqlError[]>([]);
    const canCreateOrModifyConsignments = canCreateConsignment(user);
    const isDeprecated = containsDeprecatedForms(consignment as any);

    const [sectionNames] = useState<SectionName[]>([
        type === 'CONSIGNMENT' ? SectionName.MOVEMENT : SectionName.TEMPLATE,
        SectionName.FORMS,
        SectionName.LIVESTOCK_DESCRIPTION,
        SectionName.LIVESTOCK_HISTORY,
        SectionName.FOOD_SAFETY,
        SectionName.CHEMICAL_AND_TREAMENT_HISTORY,
        SectionName.DECLARATION,
        SectionName.TRANSPORTER,
    ]);

    // show warning to user, if any future date(s) has been picked
    const notifyIfAnyDatesGreaterThanToday = useCallback(
        (sectionName: SectionName) => {
            const questionOrders: number[] = DynamicQuestions[sectionName];
            const dynamicQuestions: NonNullable<IDynamicQuestion[]> = (
                questionOrders.map((order) => consignment?.questions?.find((q) => q?.order === order)).coalesce() as unknown[] as IDynamicQuestion[]
            )
                .map((question: IDynamicQuestion) => {
                    // We could map further, but 2 levels deep is enough for now
                    const childQuestions = getAllChildQuestions(question);
                    return [...childQuestions, question];
                })
                .flat(1);

            // filter all dynamic questions that has date types
            const dateDynamicQuestions = dynamicQuestions?.filter((q) => q?.type === 'DATE');
            // set tomorrow's date to 00:00am
            const today = new Date();
            let tomorrow = new Date();
            tomorrow.setDate(today.getDate() + 1);
            tomorrow.setHours(0, 0, 0, 0);

            const anyDatesGreaterThanToday = dateDynamicQuestions?.some((qdq: IDynamicQuestion) => {
                // compare all pending & original answers to see if it's greater than today
                const hasDateGreaterThanTodayPendingAnswer = dynamicPendingAnswers.answers.some((dpa) => dpa.questionId === qdq.id && dpa.value && new Date(dpa.value).getTime() > tomorrow.getTime());
                const hasDateGreaterThanTodayOrginalAnswer = originalAnswers.some((oa) => oa.questionId === qdq.id && oa.value && new Date(oa.value).getTime() > tomorrow.getTime());
                return hasDateGreaterThanTodayPendingAnswer || hasDateGreaterThanTodayOrginalAnswer;
            });

            return anyDatesGreaterThanToday;
        },
        [consignment, dynamicPendingAnswers, originalAnswers]
    );

    /**
        Validates a section

        sectionName: The name of the section
        deepScan: Whether to validate all child questions alongside the questions itself, mainly applies to repeatables
    */
    const validateSection = useCallback(
        (sectionName: SectionName, deepScan: boolean = false) => {
            const questionOrders: number[] = DynamicQuestions[sectionName];
            // We need to flatten this, only when 'visible'
            const dynamicQuestions: NonNullable<IDynamicQuestion[]> = (
                questionOrders.map((order) => consignment?.questions?.find((q) => q?.order === order)).coalesce() as unknown[] as IDynamicQuestion[]
            )
                .map((question: IDynamicQuestion) => {
                    // We could map further, but 2 levels deep is enough for now
                    const childQuestions = getAllChildQuestions(question);
                    return [...childQuestions, question];
                })
                .flat(1);

            const repeatableDynamicQuestions = dynamicQuestions.filter((question) => question.type === 'REPEATABLE');

            // Check the updates in DynamicAnswers to make sure they're still all correct
            const isValidDynamicSection = dynamicQuestions.every((question: IDynamicQuestion) => {
                // If this isn't a 'visible' question, then skip it
                if (question.triggers !== null && question.triggers.length > 0 && !validateTrigger(question.triggers, dynamicPendingAnswers.answers, originalAnswers)) {
                    MLALogger.Log(['ConsignmentEdit', 'validateSection'], { message: 'Trigger question not valid, skipping', question });
                    return true;
                }

                // If this is a child of a repeatable and the parent isn't valid, skip
                const parentRepeatable = repeatableDynamicQuestions.firstOrDefault((rdq) => rdq.childQuestions.some((cq) => cq.id === question.id));
                if (parentRepeatable) {
                    MLALogger.Log(['ConsignmentEdit', 'validateSection'], { message: 'This is a child of a repeatable', question, repeatableDynamicQuestions });
                    // Handle for triggers
                    if (parentRepeatable.triggers !== null && parentRepeatable.triggers.length > 0 && !validateTrigger(parentRepeatable.triggers, dynamicPendingAnswers.answers, originalAnswers)) {
                        MLALogger.Log(['ConsignmentEdit', 'validateSection'], { message: 'Repeatable parent with no valid trigger', question });
                        return true;
                    }

                    // Handle for deleting repeatable nested inside a trigger
                    if (
                        parentRepeatable.triggers !== null &&
                        parentRepeatable.triggers.length > 0 &&
                        parentRepeatable.triggers.every((trigger) => {
                            const hasQuestionInDPA = dynamicPendingAnswers.answers.some((dpa) => dpa.questionId === trigger.questionId && dpa.value === trigger.value);
                            const hasQuestionInOA = originalAnswers.some((ans) => ans.questionId === trigger.questionId && ans.value === trigger.value);
                            const hasParentTrigger = hasQuestionInDPA ? hasQuestionInDPA : hasQuestionInOA;
                            const isDeletedAnswer = dynamicPendingAnswers.answers.some((dpa) => dpa.questionId === question.id && dpa.index === null);
                            return !hasParentTrigger && isDeletedAnswer;
                        })
                    ) {
                        return true;
                    }
                    // Handle for empty repeatables
                    if (parentRepeatable.triggers === null) {
                        // EPLAT-359: Handle acceptable answers for the checks due to older consignments using (now) invalid data
                        const pendingAnswer = dynamicPendingAnswers.answers.firstOrDefault((dpa) => dpa.questionId === question.id);
                        const originalAnswer = originalAnswers.firstOrDefault((oa) => oa.questionId === question.id);
                        const hasPendingAnswer = Boolean(pendingAnswer);
                        const hasOriginalAnswer = Boolean(originalAnswer);

                        MLALogger.Log(['ConsignmentEdit', 'validateSection'], { message: 'Repeatable parent with no trigger', question, hasPendingAnswer, hasOriginalAnswer, originalAnswers });
                        if ((hasPendingAnswer || hasOriginalAnswer) === false) {
                            // If this has no answers, then we want to skip
                            return true;
                        }

                        const hasAcceptableAnswers = question.acceptableAnswers?.length > 0;
                        const hasValidAnswer = hasAcceptableAnswers && question.acceptableAnswers.some((aa) => aa.value === pendingAnswer?.value || aa.value === originalAnswer?.value);

                        if (hasAcceptableAnswers && !hasValidAnswer) {
                            return false;
                        }

                        // Skip when index === null since that is for deletion
                        const questionAnswers = dynamicPendingAnswers.answers.filter((answer) => answer.questionId === question.id);
                        if (hasPendingAnswer && questionAnswers.length === 1 && questionAnswers.every((answer) => answer.index === null)) {
                            return true;
                        }
                    }
                }

                const updated = dynamicPendingAnswers?.answers?.filter((dpa) => dpa.questionId === question.id);
                if (updated && updated.length > 0) {
                    let updatedValidationResult = updated.every((item: any) => validate(question.validators, item.value));
                    MLALogger.Log(['ConsignmentEdit', 'validateSection', 'isValidDynamicSection'], {
                        updated,
                        result: updatedValidationResult,
                        question,
                    });
                    // If this is a deletion, just ignore
                    if (updated.every((item: any) => item.index === null && item.value === null)) {
                        return true;
                    }
                    return updatedValidationResult;
                }

                const existing = originalAnswers?.filter((oa) => oa.questionId === question.id);
                if (existing && existing.length > 0) {
                    let existingValidationResult = existing.every((item: any) => validate(question.validators, item.value));
                    MLALogger.Log(['ConsignmentEdit', 'validateSection', 'isValidDynamicSection'], {
                        existing,
                        result: existingValidationResult,
                        question,
                    });
                    return existingValidationResult;
                }

                let validators = question.validators;
                const isRepeatable = question.type === 'REPEATABLE';
                if (isRepeatable) {
                    // If it is a repeatable, we need to handle it differently
                    // With this, required is only triggered if there are children, otherwise run through normal routes
                    const isRepeatableRequired = getIsRequired(question.validators);
                    if (isRepeatableRequired) {
                        // Remove required since we handle it here
                        validators = validators.filter((validator) => validator.type !== 'required');
                        return validateRepeatable(question, dynamicPendingAnswers.answers, deepScan);
                    }
                }

                // If this isn't a required question, don't even bother with a fake validation
                if (validators.some((validator) => validator.type === 'required')) {
                    MLALogger.Log(['ConsignmentEdit', 'validateSection', 'isValidDynamicSection', 'leftover'], {
                        result: validate(validators, ''),
                        question,
                    });
                    return validate(validators, '');
                }

                // Default to valid
                return true;
            });

            // Check against the static side if there is one
            // Ignoring those of the Calculate type since they don't contain answers
            const staticQuestions: any[] = (StaticQuestions[sectionName] as any[]).filter((sq: IStaticQuestion) => sq.type !== 'Calculate');

            const isValidStaticSection = isStaticSection(sectionName)
                ? staticQuestions.every((question: IStaticQuestion) => {
                      if (question.forms) {
                          const hasForm = hasFormCheck(consignment?.forms, question.forms);

                          if (!hasForm) {
                              // skip if we have it defined and its not valid
                              return true;
                          }
                      }
                      let fieldFromStaticAnswers = getField<any, TAirtableConsignment>(question.field, staticPendingAnswers.answers, question.type === 'Boolean' ? false : null);
                      let fieldFromSavedConsignmentData = getField<any, TAirtableConsignment>(question.field, consignment, question.type === 'Boolean' ? false : null);
                      const currentAnswer = staticPendingAnswers.dirty ? fieldFromStaticAnswers : fieldFromSavedConsignmentData ?? fieldFromStaticAnswers;
                      return validate(question.validators, currentAnswer);
                  })
                : true;

            // Only use valid if there is ANY answers, if there are none, then return undefined (no icon)
            const hasDynamicAnswers =
                dynamicQuestions.length > 0 &&
                // dynamicPendingAnswers.answers.length > 0 &&
                dynamicQuestions.some((dq: any) => dynamicPendingAnswers.answers.some((dpa) => dpa.questionId === dq.id) || originalAnswers.some((oa) => oa.questionId === dq.id));
            const hasStaticAnswers = staticQuestions.length > 0;

            const valid = isValidDynamicSection && isValidStaticSection;
            MLALogger.Log(['ConsignmentEdit', 'validateSection'], {
                sectionName,
                isValidDynamicSection,
                isValidStaticSection,
                valid,
                hasDynamicAnswers,
                hasStaticAnswers,
                dynamicQuestions,
                staticQuestions,
                returning: hasDynamicAnswers || hasStaticAnswers ? valid : undefined,
                dpa: dynamicPendingAnswers.answers,
                sa: staticPendingAnswers.answers,
                originalAnswers,
            });
            return hasDynamicAnswers || hasStaticAnswers ? valid : undefined;
        },
        [consignment, dynamicPendingAnswers, staticPendingAnswers.answers, originalAnswers, staticPendingAnswers.dirty]
    );

    useEffect(() => {
        if (consignment?.basedOn) {
            dispatch({ type: actionTypes.copyErrors.setIsCopy, value: true });
        }
    }, [consignment?.basedOn, dispatch]);

    // This useEffect will handle the consignment Errors.
    useEffect(() => {
        const isDeleteTransporterErrorCodeExist = consignmentErrors.some((error) => error?.extensions?.data?.Code === TRANSPORTER_DELETION_ERROR_CODE);
        if (isDeleteTransporterErrorCodeExist) {
            history.push('/consignments');
        }
    }, [consignmentErrors, history]);

    const saveSection = useCallback(
        async (sectionName: SectionName) => {
            let status = false;

            // Skip 'saving' when the consignment has deprecated forms
            if (isDeprecated) {
                return true;
            }

            const dirtySection = dynamicPendingAnswers.dirty || staticPendingAnswers.dirty;

            const isValidSection = validateSection(sectionName);

            MLALogger.Log(['ConsignmentEdit', 'saveSection'], { isValidSection, dirtySection });

            if ((isValidSection === undefined || isValidSection === true) && dirtySection) {
                try {
                    setIsSaving(true);
                    if (type === 'CONSIGNMENT') {
                        // Should update the consignment if the current section dosen't have any errors.
                        if (!copyErrors.invalidSections.includes(sectionName)) {
                            await CreateOrSaveConsignment(
                                consignmentMutate,
                                {
                                    number: consignment?.number,
                                    answers: dynamicPendingAnswers.answers,
                                    ...staticPendingAnswers.answers,
                                    // Force skip validation on anything already submitted
                                    skipValidation: consignment?.status !== 'DRAFT',
                                },
                                envdAccountId
                            );
                        }
                    } else {
                        // For templates, we don't want all fields, so lets strip them off here
                        await CreateOrSaveTemplate(
                            templateMutate,
                            {
                                name: staticPendingAnswers.answers.name ?? '',
                                number: consignment?.number,
                                answers: dynamicPendingAnswers.answers,
                                ...Object.fromEntries(
                                    Object.entries(staticPendingAnswers.answers)
                                        .map((x) => (x['0'] === 'declaration' ? null : x))
                                        .coalesce()
                                ),
                            },
                            envdAccountId
                        );
                    }

                    setIsSaving(false);
                    setWarningMessage(undefined);
                    dispatch({ type: actionTypes.triggerValidatorCount.resetTriggerValidatorCount });
                    status = true;
                } catch (e) {
                    var errors = e as GraphqlError[];
                    var warningMessage = errors
                        .filter((error) => error.extensions?.data?.OutcomeStatus.toLowerCase() === GraphqlErrorType.Warning.toLowerCase())
                        .map((error) => error.extensions?.data?.Message ?? error.message)
                        .join('\n');
                    MLALogger.Log(['ConsignmentEdit', 'saveSection'], { message: 'Save was unsuccessful', e }, 'error');
                    if (!copyErrors.isCopy) {
                        setWarningMessage(warningMessage);
                    }
                    if (copyErrors.isCopy && !copyErrors.errors.some((err) => err.section === sectionName)) {
                        status = true;
                        setIsSaving(false);
                    }
                    const consignmentError = errors.filter((error) => error.extensions?.data?.OutcomeStatus.toLowerCase() === GraphqlErrorType.Success.toLowerCase());
                    if (!isEmpty(consignmentError)) {
                        setConsignmentErrors(consignmentError);
                    }
                }
            } else if (!dirtySection) {
                // Allow changing when nothing has changed
                setWarningMessage(undefined);
                dispatch({ type: actionTypes.triggerValidatorCount.resetTriggerValidatorCount });
                if (!copyErrors.errors.some((err) => err.section === sectionName)) {
                    status = true;
                    setIsSaving(false);
                }
            } else {
                // This is an invalid section so display error message
                const message =
                    type === 'CONSIGNMENT'
                        ? 'Unable to save the current section. Please ensure all required questions are answered'
                        : `We are unable to save your template. Please check you have answered any new questions as they may have changed since this template was created.`;
                setWarningMessage(message);
                // Show error messages inline for the questions
                dispatch({ type: actionTypes.triggerValidatorCount.increaseTriggerValidatorCount });
            }

            MLALogger.Log(['ConsignmentEdit', 'saveSection'], { sectionName, dirtySection, isValidSection, status });

            return status;
        },
        [
            isDeprecated,
            dynamicPendingAnswers,
            staticPendingAnswers,
            validateSection,
            type,
            dispatch,
            consignmentMutate,
            consignment,
            templateMutate,
            copyErrors.invalidSections,
            copyErrors.isCopy,
            copyErrors.errors,
            envdAccountId,
        ]
    );

    const viewAndSaveConsignmentCallback = useCallback(async () => {
        let shouldNavigate = consignment?.status === 'SUBMITTED' || consignment?.status === 'LOCKED';

        const sectionName = sectionRoute as SectionName;

        if (isValidPostSubmitSection(sectionName)) {
            // If it is one we can update, then lets try to save it
            const saved = await saveSection(sectionName);
            if (saved) {
                shouldNavigate = true;
            } else {
                shouldNavigate = false;
            }
        }

        if (shouldNavigate) {
            history.push(`/consignments/summary/${consignment?.number}`);
        }
    }, [consignment, sectionRoute, saveSection, history]);

    const saveDraftCallback = useCallback(async () => {
        const sectionName = sectionRoute as SectionName;

        // If it is one we can update, then lets try to save it
        await saveSection(sectionName);
    }, [sectionRoute, saveSection]);

    const submitConsignmentCallback = useCallback(async () => {
        try {
            // Save if dirty
            // TODO: Move status and skipValidation to saveSection to reduce calls
            const saved = await saveSection(sectionRoute as SectionName);
            if (saved) {
                await CreateOrSaveConsignment(
                    consignmentMutate,
                    {
                        number: consignment?.number,
                        status: ConsignmentStatus.SUBMITTED,
                        skipValidation: true,
                    },
                    envdAccountId
                );
                history.push(`/consignments/summary/${consignment?.number}`);
            } else {
                // Now close the modal
                if (confirmSubmitRef.current) {
                    confirmSubmitRef.current.hide();
                }
            }
        } catch (e) {
            console.error('[ConsignmentEditFragment] error submitting', e);
            setWarningMessage('An error has occurred, changes may not be saved');
        }
    }, [sectionRoute, consignment, saveSection, consignmentMutate, history, envdAccountId]);

    const saveOnExitCallback = useCallback(async () => {
        try {
            const toPath = generic.toPath;
            dispatch({ type: actionTypes.generic.resetGeneric });
            const success = await saveSection(sectionRoute as SectionName);
            if (success) {
                if (toPath.startsWith('http')) {
                    // This is an external link so just set window.location
                    window.location.href = toPath;
                } else {
                    history.replace(toPath);
                }
            } else {
                history.replace(toPath);
            }
        } catch (e) {
            console.error('[ConsignmentEditFragment] error saving', e);
            setWarningMessage('An error has occurred, changes may not be saved');
            if (saveOnExitRef.current) {
                saveOnExitRef.current.hide();
            }
        }
    }, [saveSection, sectionRoute, generic.toPath, dispatch, history]);

    const saveOnExitNavigateOut = useCallback(() => {
        const toPath = generic.toPath;
        dispatch({ type: actionTypes.generic.resetGeneric });
        if (toPath.startsWith('http')) {
            // This is an external link so just set window.location
            window.location.href = toPath;
        } else {
            history.push(toPath);
        }
    }, [generic, dispatch, history]);

    const convertTemplateCallback = useCallback(async () => {
        if (consignment) {
            const newTemplate = await ConvertTemplate(
                convertTemplateMutate,
                {
                    number: consignment.number!,
                    name: '',
                },
                envdAccountId
            );
            // Navigate to the new template page
            history.push(`/templates/edit/${newTemplate.number}`);
        }
    }, [consignment, convertTemplateMutate, history, envdAccountId]);

    const handler = useCallback(
        // TODO: Can we pass in previousSectionName instead?
        async (nextSectionName: SectionName, previousSectionName: SectionName) => {
            MLALogger.Log(['ConsignmentEdit', 'handler'], { nextSectionName, previousSectionName });
            const shouldNavigate = await saveSection(previousSectionName);
            // Validate the current section to see if we should navigate and save or show an error
            if (shouldNavigate) {
                history.push(`/${type === 'CONSIGNMENT' ? 'consignments/edit' : 'templates/edit'}/${consignment?.number}/${nextSectionName}`);
            }
        },
        // eslint-disable-next-line
        [consignment, type, saveSection]
    );

    const getIcon = useCallback(
        (sectionName: SectionName) => {
            MLALogger.Log(['ConsignmentEdit', 'getIcon'], { sectionName });
            // Validate the current section to see if we should navigate and save or show an error
            const valid = validateSection(sectionName);
            if (valid === undefined) {
                MLALogger.Log(['ConsignmentEdit', 'getIcon'], { message: 'Valid is undefined', valid, sectionName });
                return undefined;
            }
            return isSaving && sectionName === sectionRoute ? 'loading' : valid ? 'success' : 'error';
        },
        [isSaving, validateSection, sectionRoute]
    );

    // ENVDB-1050: added optional disabled attribute so if any section doesnt have any questions
    // then the tab would be disabled
    const headers = useMemo(
        () => [
            {
                title: type === 'TEMPLATE' ? 'Template Name' : 'Movement',
                section: type === 'TEMPLATE' ? SectionName.TEMPLATE : SectionName.MOVEMENT,
                handler,
                getIcon,
            },
            {
                title: 'Forms',
                section: SectionName.FORMS,
                handler,
                getIcon,
            },
            {
                title: 'Livestock',
                section: SectionName.LIVESTOCK_DESCRIPTION,
                handler,
                getIcon,
                hidden: !(validateConsignment(consignment, SectionName.LIVESTOCK_DESCRIPTION).numOfQues > 0),
            },
            {
                title: 'History',
                section: SectionName.LIVESTOCK_HISTORY,
                handler,
                getIcon,
                hidden: !(validateConsignment(consignment, SectionName.LIVESTOCK_HISTORY).numOfQues > 0),
            },
            {
                title: 'Food Safety',
                section: SectionName.FOOD_SAFETY,
                handler,
                getIcon,
                hidden: !(validateConsignment(consignment, SectionName.FOOD_SAFETY).numOfQues > 0),
            },
            {
                title: 'Chemical / Treatments',
                section: SectionName.CHEMICAL_AND_TREAMENT_HISTORY,
                handler,
                getIcon,
                hidden: !(validateConsignment(consignment, SectionName.CHEMICAL_AND_TREAMENT_HISTORY).numOfQues > 0),
            },
            {
                title: 'Declaration',
                section: SectionName.DECLARATION,
                handler,
                getIcon,
            },
            {
                title: 'Transporter',
                section: SectionName.TRANSPORTER,
                handler,
                getIcon,
            },
        ],
        [type, handler, getIcon, consignment]
    );

    // EPLAT-456: Clear the error message if the page becomes dirty.
    useEffect(() => {
        if (dynamicPendingAnswers.dirty || staticPendingAnswers.dirty) {
            setWarningMessage(undefined);
        }
    }, [dynamicPendingAnswers.dirty, staticPendingAnswers.dirty]);

    return (
        <div className={classnames('ConsignmentEditPage', { 'ConsignmentEditPage--Locked': consignmentLoading || templateLoading })}>
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';

                .ConsignmentEditPage {
                    &--Locked {
                        user-select: none;
                        pointer-events: none;
                    }

                    &--Header {
                        &--Spacer {
                            padding: grid(4) 0;
                        }

                        &--Desktop {
                            position: fixed;
                            height: grid(13);
                            z-index: 8;
                            top: grid(2);
                        }

                        &--Mobile {
                            flex-direction: row;
                            align-items: baseline;

                            padding: grid(4) 0;

                            &-Left {
                                margin: grid(2) 0;

                                div {
                                    display: flex;
                                }
                            }

                            &-Right {
                                position: fixed;
                                height: grid(13);
                                z-index: 8;
                                top: grid(2);
                            }
                            &--Mobile {
                                flex-direction: row;
                                align-items: baseline;

                                padding: grid(4) 0;
                                &-Left {
                                    margin: grid(2) 0;

                                    div {
                                        display: flex;
                                    }
                                }
                                &-Right {
                                    position: fixed;
                                    top: grid(3);
                                    right: 0;
                                    z-index: 9;

                                    :global(button) {
                                        padding: grid(1);
                                        margin-right: grid(1);
                                    }
                                }
                            }
                        }
                    }
                }
                .list-item {
                    list-style-position: outside;
                }
            `}</style>

            <FeedbackButton url={Config.ISC_CUSTOMERFEEDBACK_URL} />
            <div className="ConsignmentEditPage--Header--Spacer" />

            {isDesktop && (
                <div className="ConsignmentEditPage--Header--Desktop" style={{ left: headerX }}>
                    {type === 'CONSIGNMENT' && consignment?.status === ConsignmentStatus.DRAFT && canCreateOrModifyConsignments && (
                        <>
                            <Button buttonType="secondary" buttonSize="small" onClick={saveDraftCallback} disabled={consignmentLoading || templateLoading}>
                                Save draft
                            </Button>
                            <Button
                                buttonType={copyErrors?.errors.length > 0 ? 'disable' : 'primary'}
                                buttonSize="small"
                                onClick={() => confirmSubmitRef?.current?.show()}
                                disabled={consignmentLoading || templateLoading || copyErrors?.errors.length > 0}
                            >
                                Submit Consignment
                            </Button>
                        </>
                    )}
                    {type === 'CONSIGNMENT' && (consignment?.status === ConsignmentStatus.SUBMITTED || consignment?.status === ConsignmentStatus.LOCKED) && (
                        <Button buttonType="primary" buttonSize="small" onClick={viewAndSaveConsignmentCallback} disabled={consignmentLoading || templateLoading}>
                            {(dynamicPendingAnswers.dirty || staticPendingAnswers.dirty) && !isDeprecated ? 'Save changes' : 'Back to Summary Page'}
                        </Button>
                    )}
                </div>
            )}

            {isTabletOrMobile && (
                <>
                    {type === 'CONSIGNMENT' && consignment?.status === ConsignmentStatus.DRAFT && canCreateOrModifyConsignments && (
                        <div className="ConsignmentEditPage--Header--Mobile-Right">
                            <Button
                                buttonType={copyErrors?.errors.length > 0 ? 'disable' : 'primary'}
                                buttonSize="xsmall"
                                onClick={() => confirmSubmitRef?.current?.show()}
                                disabled={consignmentLoading || templateLoading || copyErrors?.errors.length > 0}
                            >
                                Submit
                            </Button>
                        </div>
                    )}
                    {type === 'CONSIGNMENT' && (consignment?.status === ConsignmentStatus.SUBMITTED || consignment?.status === ConsignmentStatus.LOCKED) && (
                        <div className="ConsignmentEditPage--Header--Mobile-Right">
                            <Button buttonType="primary" buttonSize="xsmall" onClick={viewAndSaveConsignmentCallback} disabled={consignmentLoading || templateLoading}>
                                {(dynamicPendingAnswers.dirty || staticPendingAnswers.dirty) && !isDeprecated ? 'Save changes' : 'Back to Summary Page'}
                            </Button>
                        </div>
                    )}
                </>
            )}

            <ConfirmModal
                actions={[
                    {
                        style: 'secondary',
                        text: 'Save draft',
                        action: async () => {
                            await saveDraftCallback();
                            // eslint-disable-next-line no-unused-expressions
                            confirmSubmitRef?.current?.hide();
                        },
                    },
                    {
                        style: 'primary',
                        text: 'Submit',
                        action: submitConsignmentCallback,
                    },
                ]}
                ref={confirmSubmitRef}
                modalId={`confirm-modal`}
                title={`Are you sure you're ready to submit this consignment?`}
                onCancel={() => setWarningMessage(undefined)}
                modalWarning={warningMessage}
            >
                <div className="m-b-8">
                    By submitting this consignment, only the following will remain editable:
                    <ul>
                        <li>Movement date</li>
                        <li>Livestock description</li>
                        <li>Transporter details</li>
                    </ul>
                </div>
                <p>If you need to make other changes before printing, click SAVE DRAFT and you'll find it in your consignments list for you to complete later.</p>
                <p>Please note that this consignment will no longer be editable from 7 days AFTER the entered movement date.</p>
                <p>Please note that this consignment will not be automatically sent to any third party.</p>
            </ConfirmModal>

            <ConfirmModal
                actions={[
                    {
                        style: 'primary',
                        text: 'Save changes',
                        action: saveOnExitCallback,
                    },
                ]}
                ref={saveOnExitRef}
                modalId={`confirm-modal`}
                title={`You have unsaved changes`}
                cancelText={`Don't save`}
                onCancel={saveOnExitNavigateOut}
                modalWarning={warningMessage}
            >
                Do you want to save before you leave this page?
            </ConfirmModal>

            <ConfirmModal
                actions={[
                    {
                        style: 'primary',
                        text: 'Convert to latest',
                        action: convertTemplateCallback,
                    },
                ]}
                ref={convertTemplateRef}
                modalId={`confirm-modal`}
                title={`This template has outdated forms`}
                cancelText={'Go back'}
                onCancel={() => history.push('/templates')}
                modalWarning={warningMessage}
            >
                Do you want to update this template to use all the newer versions of the forms automatically? If not, you will be taken back to the template listing page.
            </ConfirmModal>

            {type === 'CONSIGNMENT' && consignment?.status === ConsignmentStatus.SUBMITTED && (
                <Alert type={AlertType.Info} title={CONSIGNMENT_SUBMITTED_TITLE} subtitle={<>{CONSIGNMENT_SUBMITTED_MESAGE}</>} />
            )}

            {warningMessage && (
                <Alert type={AlertType.Error} title="There was an error while saving">
                    <ul className="list-item">
                        {warningMessage.split('\n').map((msg, idx) => {
                            return <li key={idx}>{msg}</li>;
                        })}
                    </ul>
                </Alert>
            )}

            {notifyIfAnyDatesGreaterThanToday(SectionName.CHEMICAL_AND_TREAMENT_HISTORY) && (
                <Alert type={AlertType.Warn} title="Please note the treatment date you have entered is in the future." subtitle="Please check this and change if this date is not correct." />
            )}

            {copyErrors?.errors?.length ? (
                <Alert title="Please fix the following errors before we save your form" type={AlertType.Error}>
                    <ul>
                        {copyErrors.errors.map(({ question, messages, section }, idx) => {
                            const sectionTitle = headers.find((header) => header.section === section)?.title;

                            return (
                                <li key={idx}>
                                    [{sectionTitle}] {question} ({messages.join(', ')})
                                </li>
                            );
                        })}
                    </ul>
                </Alert>
            ) : null}

            <Wizard
                headers={headers}
                footer={{
                    onPrev: async (activeIndex: number) => {
                        const newSectionName = sectionNames[activeIndex];
                        window.scrollTo({ top: 0 });
                        return saveSection(newSectionName);
                    },
                    onNext: async (activeIndex: number) => {
                        const newSectionName = sectionNames[activeIndex];
                        window.scrollTo({ top: 0 });
                        return saveSection(newSectionName);
                    },
                    onCancel: () => history.push(`/consignments/summary/${consignment?.number}`),
                    onSubmit: () => confirmSubmitRef?.current?.show(),
                    showSubmit: consignment?.status === ConsignmentStatus.DRAFT && isMyConsignment(consignment, user) && type === 'CONSIGNMENT',
                }}
            >
                {sectionNames.map((sectionName) => (
                    <WizardStep key={sectionName}>
                        <Section name={sectionName} data={consignment} />
                    </WizardStep>
                ))}
            </Wizard>
        </div>
    );
};

//#endregion
