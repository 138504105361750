import { ClearIcon, HelpIcon } from '@assets/icons';
import ClickableIcon from '@components/ClickableIcon';
import Conditional from '@components/Form/Conditional';
import Modal from '@components/Modal';
import { useGlobalState } from '@state';
import { IDynamicQuestion } from '@typings';
import React, { useCallback, useMemo, useState } from 'react';
import ReactMarkdown from 'react-markdown';

import DynamicBooleanQuestion from './DynamicBooleanQuestion';
import DynamicDateMonthQuestion from './DynamicDateMonthQuestion';
import DynamicDateQuestion from './DynamicDateQuestion';
import DynamicDateTimeQuestion from './DynamicDateTimeQuestion';
import DynamicFileQuestion from './DynamicFileQuestion';
import DynamicImageQuestion from './DynamicImageQuestion';
import DynamicMultipleChoiceQuestion from './DynamicMultipleChoiceQuestion';
import DynamicNumberQuestion from './DynamicNumberQuestion';
import DynamicReadOnlyQuestion from './DynamicReadOnlyQuestion';
import DynamicRepeatableQuestion from './DynamicRepeatableQuestion';
import DynamicSingleChoiceQuestion from './DynamicSingleChoiceQuestion';
import DynamicStringQuestion from './DynamicStringQuestion';

export interface IDynamicQuestionProps {
    question: IDynamicQuestion;
    index: number | null;

    // Helpers
    isChildQuestion?: boolean;
    showTitle?: boolean;
    allQuestionsFromSection?: IDynamicQuestion[];
}
const notNullOrEmpty = 'NotNullOrEmpty';
const DynamicQuestion: React.FC<IDynamicQuestionProps> = (props) => {
    const { question, index, isChildQuestion } = props;
    const [{ originalAnswers, dynamicPendingAnswers }] = useGlobalState();
    const [showModal, setShowModal] = useState<boolean>(false);

    // Handle constants inside modal to avoid re-renders
    const modalHeader = useMemo(() => {
        return (
            <div className={'QuestionModalNotes--Header'}>
                <h3>Help</h3>
                <ClickableIcon icon={<ClearIcon />} alt="Remove" onClick={() => setShowModal(false)} classNames={'QuestionModalNotesClose'} />
            </div>
        );
    }, []);

    const help = question.help?.trim();
    const modalChildren = useMemo(() => {
        return (
            <>
                <h3 className="m-b-16">{question.text}</h3>
                <ReactMarkdown children={help ?? ''} className="QuestionModalNotes--Content" />
            </>
        );
    }, [question.text, help]);

    const toggleModal = useCallback(() => setShowModal((m) => !m), []);

    const childQuestions = useMemo(() => {
        return question.childQuestions?.map((cq) => ({ ...cq, readOnly: cq.readOnly ?? question.readOnly })) ?? [];
    }, [question.childQuestions, question.readOnly]);

    if (question.triggers) {
        // TODO: Handle index for repeatable questions with no index
        const parentQuestionAnswer =
            dynamicPendingAnswers.answers.firstOrDefault((x) => (question.triggers?.some((trigger) => x.questionId === trigger.questionId) ?? false) && x.index === props.index) ??
            originalAnswers.firstOrDefault((x) => (question.triggers?.some((trigger) => x.questionId === trigger.questionId) ?? false) && x.index === props.index);
        if (parentQuestionAnswer) {
            // Don't show if the trigger doesn't match
            if (!question.triggers.some((x) => x.value === notNullOrEmpty) && !question.triggers.some((x) => x.value === parentQuestionAnswer.value)) {
                return null;
            }
        } else {
            // If no matches found then we can't have a match
            return null;
        }
    }

    // This is for debugging, display something when it is an unmapped question type
    let render = (
        <>
            <h4>{question.text}</h4>
            {originalAnswers
                ?.filter((answer) => answer.questionId === question.id)
                .map((answer) => (
                    <h4 key={`${answer.value}${answer.index}`}>
                        V: {answer.value} - I: {answer.index}
                    </h4>
                ))}
        </>
    );

    if (question.type === 'SINGLE_CHOICE') {
        render = <DynamicSingleChoiceQuestion {...props} />;
    } else if (question.type === 'MULTIPLE_CHOICE') {
        render = <DynamicMultipleChoiceQuestion {...props} />;
    } else if (question.type === 'STRING') {
        render = <DynamicStringQuestion {...props} />;
    } else if (question.type === 'NUMBER') {
        render = <DynamicNumberQuestion {...props} />;
    } else if (question.type === 'DATE') {
        render = <DynamicDateQuestion {...props} />;
    } else if (question.type === 'DATE_TIME') {
        render = <DynamicDateTimeQuestion {...props} />;
    } else if (question.type === 'IMAGE' || question.type === 'SIGNATURE') {
        render = <DynamicImageQuestion {...props} />;
    } else if (question.type === 'FILE') {
        render = <DynamicFileQuestion {...props} />;
    } else if (question.type === 'BOOLEAN') {
        render = <DynamicBooleanQuestion {...props} />;
    } else if (question.type === 'REPEATABLE') {
        return <DynamicRepeatableQuestion {...props} />;
    } else if (question.type === 'DISPLAY') {
        // This is purely a display question, so just display it as such
    } else if (question.type === 'DATE_MONTH') {
        // HACK: Introduced to display a NUMBER as a MONTH PICKER
        render = <DynamicDateMonthQuestion {...props} />;
    }

    // Display it in a read only format
    // Vertical when it is a standard question
    // Horizontal when it is a child question within a repeatable block
    if (question.readOnly && question.type !== 'IMAGE' && question.type !== 'FILE' && question.type !== 'SIGNATURE' && !question?.triggers?.some((x) => x.value === notNullOrEmpty)) {
        render = <DynamicReadOnlyQuestion {...props} vertical={!Boolean(isChildQuestion) && (question.triggers === null || question.triggers.length === 0)} />;
    }

    const dataTestId = process.env.CI || process.env.NODE_ENV === 'test' ? question.id : undefined;

    return (
        <div className="Question" data-cy={dataTestId}>
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';

                .Question {
                    position: relative;
                    padding: grid(2) 0;
                    width: 100%

                    :global(&--Info) {
                        position: absolute;
                        top: grid(1);
                        right: grid(1);
                        color: $color-grey47;
                        z-index: 1;
                    }

                    :global(svg) {
                        cursor: pointer;
                    }
                }

                :global(.QuestionModalNotesClose) {
                    flex: 1 0 auto;
                    justify-content: flex-end;
                    color: $color-copy;

                    @media (prefers-color-scheme: dark) {
                        color: $color-white;
                    }
                }

                :global(.QuestionModalNotes--Header) {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex: 1 1 auto;

                    p {
                        margin-right: grid(3);
                    }
                }

                :global(.QuestionModalNotes--Content) {
                    :global(p) {
                        word-break: break-all;
                        white-space: pre-wrap;
                    }
                }
            `}</style>
            {help && <HelpIcon className="icon-xs Question--Info" onClick={() => setShowModal((s) => !s)} role="button" aria-pressed="false" />}

            {render}

            {childQuestions.length > 0 && (
                <Conditional show>
                    {childQuestions.map((cq, idx) => (
                        <DynamicQuestion key={`${cq.id}${idx}`} question={cq} index={index} isChildQuestion />
                    ))}
                </Conditional>
            )}

            {help && (
                <Modal id={'modal-question-notes'} show={showModal} onOutClick={toggleModal} modalHeader={modalHeader} zIndex={10}>
                    {modalChildren}
                </Modal>
            )}
        </div>
    );
};

export default DynamicQuestion;
