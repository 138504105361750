import { InfoIcon } from '@assets/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { actionTypes, useUserState } from '@common/context/userContext';
import Button from '@components/Button';
import Input from '@components/Form/Input';
import ToggleSwitch from '@components/Form/ToggleSwitch';
import createOrSaveUser, { CreateOrSaveUserMutation } from '@containers/Consignments/mutations/createOrSaveUserMutation';
import { IUserProfileFields } from '@typings';
import { INVALID_PHONE_NUMBER, PHONE_REGEX } from '@utils/constants';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'relay-hooks';

import { LinkedAccount } from './LinkedAccount';

export const checkValidField = (value: string) => {
    return value?.trim()?.length > 0 ? true : false;
};

export interface accountRadioButtonType {
    id: string;
    title: string;
    selected: boolean;
}

export const TransportInfoDialogBox = () => {
    return (
        <>
            <InfoIcon alt="Info" style={{ marginTop: '15px', marginLeft: '10px', marginRight: '10px', color: 'grey' }} />
            <div style={{ marginTop: '15px', marginBottom: '15px', width: '90%' }}>
                <h6>
                    If you transport your own livestock, please disable this option. Having a transporter profile linked to your myMLA login allows access to your consignments ahead of time. More help
                    available{' '}
                    <a href="https://www.integritysystems.com.au/on-farm-assurance/national-vendor-declaration-nvd/envd-app/help/#transporters">
                        <b>here.</b>
                    </a>
                </h6>
            </div>
        </>
    );
};

export const UserConsentInfoBox = () => {
    return (
        <>
            <InfoIcon alt="Info" style={{ marginTop: '15px', marginLeft: '10px', marginRight: '10px', color: 'grey' }} />
            <div style={{ marginTop: '15px', marginBottom: '15px', width: '90%' }}>
                <h6 className="linked-account-text">
                    ISC is collecting your personal information to enable electronic completion of the National Vendor Declaration and other documents. By providing ISC with your personal information,
                    you consent to the collection and handling of your personal information in accordance with ISC's{' '}
                    <a href="https://www.integritysystems.com.au/privacy-website/">
                        {' '}
                        <b>Privacy Policy.</b>
                    </a>{' '}
                    In some states, providing us with the requested information is required by State and Territory Law.
                </h6>
            </div>
        </>
    );
};

const Register: React.FC = () => {
    const userParam: IUserProfileFields = {
        firstName: '',
        lastName: '',
        mobile: '',
        reg_num: '',
        isValidFirstName: undefined,
        isValidLastName: undefined,
        isValidMobileNum: undefined,
        isValidRegNum: undefined,
        isCarrier: false,
        defaultAccount: '',
        receiveEmails: true,
    };
    const history = useHistory();
    const [userFields, setUserFields] = useState<IUserProfileFields>(userParam);
    const [showInfoContainer, setShowInfoContainer] = useState<Boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [isError, setError] = useState<boolean>(false);
    const [mutate] = useMutation(CreateOrSaveUserMutation);
    const [{ user }, dispatch] = useUserState();
    const { logout } = useAuth0();

    const setMobileNumber = (value: string) => {
        if (!checkValidField(value) || !PHONE_REGEX.test(value)) {
            setUserFields({ ...userFields, mobile: value, isValidMobileNum: false, errMobile: INVALID_PHONE_NUMBER });
            return;
        }
        setUserFields({ ...userFields, mobile: value, isValidMobileNum: true });
    };

    const toggle = () => {
        setShowInfoContainer(!showInfoContainer);
    };

    useEffect(() => {
        setTimeout(() => {
            setError(false);
        }, 4000);
    }, [isError]);

    const callRegisterService = async (userFields: IUserProfileFields) => {
        const dataParam = {
            defaultAccount: userFields?.defaultAccount || user?.claims?.accounts[0]?.envdAccountId,
            firstName: userFields?.firstName,
            lastName: userFields?.lastName,
            mobilePhone: userFields?.mobile,
            isTransporter: userFields?.isCarrier,
            rego: userFields?.reg_num,
            authId: user?.claims?.auth0Id,
            receiveEmails: userFields?.receiveEmails,
        };
        setLoading(true);
        const response = await createOrSaveUser(mutate, dataParam);
        setLoading(false);

        if (response) {
            dispatch({
                type: actionTypes.user.setUserClaims,
                value: response,
            });
            history.replace('/consignments');
        } else {
            setError(true);
        }
    };

    const navigateBack = () => {
        // If Newuser logout else redirect to the dashboard
        if (user?.claims?.isNewUser) {
            sessionStorage.clear();
            dispatch({
                type: actionTypes.user.resetUserParam,
                value: null,
            });
            logout({ logoutParams: { returnTo: window.location.origin } });
        } else {
            history.push('/');
        }
    };

    const onSubmit = () => {
        const isValidFName = checkValidField(userFields.firstName);
        const isValidLName = checkValidField(userFields.lastName);
        const isValidMob = checkValidField(userFields.mobile) && PHONE_REGEX.test(userFields.mobile);
        const isValidReg = userFields.isCarrier ? checkValidField(userFields?.reg_num || '') : undefined;

        setUserFields({
            ...userFields,
            isValidFirstName: isValidFName,
            isValidLastName: isValidLName,
            isValidMobileNum: isValidMob,
            isValidRegNum: isValidReg,
            errMobile: !isValidMob ? INVALID_PHONE_NUMBER : '',
        });

        if (isValidFName && isValidLName && isValidMob && ((isValidReg === undefined && !userFields.isCarrier) || isValidReg)) {
            callRegisterService(userFields);
            return;
        }
    };

    const selectedPIC = (value: accountRadioButtonType | undefined) => {
        setUserFields({ ...userFields, defaultAccount: value?.id });
    };

    return (
        <>
            <div className="register-container">
                <div className="container mid-container flex-column" style={{ height: userFields?.isCarrier && showInfoContainer ? '100vh' : '90vh' }}>
                    <div className="container-content">
                        <h1 className="register-description">Let's set up your eNVD profile</h1>
                        <LinkedAccount selectedPic={selectedPIC} />
                        <h2 className="linked-account-text" data-cy="ask-info-text">
                            Tell us more about yourself
                        </h2>
                        <h4 className="linked-account-text" data-cy="contact-text">
                            Please enter your contact details.
                        </h4>
                        <div>
                            <div className="input-name">
                                <div style={{ width: '250px' }}>
                                    <Input
                                        data-cy="first-name"
                                        errorText={'This value is Required.'}
                                        isValid={userFields?.isValidFirstName}
                                        labelText={'First Name'}
                                        value={userFields.firstName || ''}
                                        onChange={(e) => setUserFields({ ...userFields, firstName: e.target.value, isValidFirstName: checkValidField(e.target.value) })}
                                    />
                                </div>
                                <div style={{ width: '150px' }}>
                                    <Input
                                        data-cy="last-name"
                                        errorText={'This value is Required.'}
                                        isValid={userFields?.isValidLastName}
                                        labelText={'Last Name'}
                                        value={userFields.lastName || ''}
                                        onChange={(e) => setUserFields({ ...userFields, lastName: e.target.value, isValidLastName: checkValidField(e.target.value) })}
                                    />
                                </div>
                            </div>
                            <div style={{ width: '420px' }}>
                                <Input
                                    data-cy="mobile"
                                    errorText={userFields?.errMobile}
                                    isValid={userFields?.isValidMobileNum}
                                    labelText={'Phone Number'}
                                    value={userFields.mobile || ''}
                                    onChange={(e) => setMobileNumber(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="carrier-display">
                            <h3 className="linked-account-text">Are you a Livestock Carrier?</h3>
                            <Button buttonType="link" buttonSize="xsmall" onClick={toggle}>
                                <InfoIcon alt="Info" />
                            </Button>
                            <div className="check-box">
                                <ToggleSwitch
                                    className="vehicle-checkbox"
                                    checked={userFields?.isCarrier || false}
                                    onChange={() => setUserFields({ ...userFields, isCarrier: !userFields.isCarrier })}
                                />
                            </div>
                        </div>
                        {showInfoContainer && (
                            <div className="info-panel">
                                <TransportInfoDialogBox />
                            </div>
                        )}
                        {userFields?.isCarrier && (
                            <div style={{ width: '250px' }}>
                                <Input
                                    data-cy="vehicle-reg-no"
                                    isValid={userFields?.isValidRegNum}
                                    errorText={'This value is Required.'}
                                    labelText={'Vehicle Rego Number'}
                                    value={userFields.reg_num || ''}
                                    onChange={(e) => setUserFields({ ...userFields, reg_num: e.target.value, isValidRegNum: userFields.isCarrier && checkValidField(e.target.value) })}
                                />
                            </div>
                        )}
                        <div className="info-panel">
                            <UserConsentInfoBox />
                        </div>
                        {isError && (
                            <div className="error-panel">
                                <h6 className="error-text">Please try again later.</h6>
                            </div>
                        )}
                        <div className="action-buttons">
                            {user?.claims?.isNewUser && <Button buttonType="primary" buttonSize="small" children="Finish profile set up" action={onSubmit} data-cy="save-button" loading={loading} />}
                            <Button buttonType="secondary" buttonSize="small" children="Cancel" action={navigateBack} data-cy="cancel-button" />
                        </div>
                    </div>
                </div>
            </div>
            <style jsx>{`
                @import 'mixins';
                @import 'vars';
                @import 'layout';

                .info-panel {
                    display: flex;
                    flex: 2 0 65%;
                    border: 2px solid $grey-border;
                    border-radius: 5px;
                    height: auto;
                    flex-direction: row;
                    width: 33vw;
                    margin-bottom: 10px;
                    @media (max-width: $lg-min) {
                        width: 90%;
                    }
                }

                .error-panel {
                    display: flex;
                    flex: 2 0 65%;
                    border: 2px solid $color-error-hover;
                    border-radius: 5px;
                    height: auto;
                    flex-direction: row;
                    width: 33vw;
                    margin-bottom: 10px;
                    justify-content: center;
                    @media (max-width: $lg-min) {
                        width: 90%;
                    }
                }

                .error-text {
                    color: $color-error;
                    font-weight: bold;
                }

                .input-name {
                    display: flex;
                    gap: 2vh;
                }

                .vehicle-reg-input {
                    width: 200px;
                }

                .check-box {
                    margin-top: -5px;
                    margin-left: 5px;
                }

                :global(Svg) {
                    color: $color-grey60;
                    margin-left: 10px;
                    margin-right: 10px;
                }

                :global(p) {
                    color: $color-grey60;
                }

                :global(a) {
                    color: $isc-blue;
                }

                :global(.Button--X-Small) {
                    margin-top: -12px;
                }

                .carrier-display {
                    display: flex;
                }

                .container-content {
                    margin-left: 5vw;
                    margin-right: 2vw;
                    overflow-y: scroll;
                    overflow-x: hidden;
                }

                .mid-container {
                    padding: 10px;
                    margin: auto;
                    justify-content: space-evenly;
                    box-sizing: border-box;
                    background-color: $color-white;
                    border: 0.8px solid #cbd2df;
                    border-radius: 4px;
                    @media (prefers-color-scheme: dark) {
                        background-color: darken($color-white, 80%);
                    }
                }

                .linked-account-text {
                    color: $generic-text;
                    margin-bottom: 2vh;
                    @media (prefers-color-scheme: dark) {
                        color: $color-white;
                    }
                }

                .register-description {
                    color: $isc-blue;
                    margin-bottom: 20px;
                }

                .action-buttons {
                    margin-top: 3vh;
                    display: flex;
                    flex-direction: row-reverse;
                    gap: 5vh;
                }

                .register-container {
                    width: 100vw;
                    height: 100vh;
                    background-image: url('img/BlueWave.svg');
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    display: flex;
                }

                :global(.label) {
                    padding: 0 32px 0 0 !important;
                }
            `}</style>
        </>
    );
};

export default Register;
