import { useUserState } from '@common/context/userContext';
import { WarningsContext } from '@common/context/warningsContext';
import FeedbackButton from '@components/FeedbackButton';
import { SelectableListItem } from '@components/Form/SelectableList';
import Loader from '@components/Loader';
import Config from '@config';
import { IPerson } from '@typings';
import { ConsignmentSpecies, SectionName } from '@utils/enums';
import { ALL_DEPRECATED_FORMS, ALL_NFAS_FORMS, getFormAlias, IFormAlias, mapProgramToForm } from '@utils/form-alias';
import MLALogger from '@utils/logger';
import { mapPersonToEntity, mapUserToPerson } from '@utils/person';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { graphql, useMutation, useQuery } from 'relay-hooks';

import { ConsignmentAddQuery } from './__generated__/ConsignmentAddQuery.graphql';
import ConsignmentAddStep1 from './components/ConsignmentAddStep1';
import ConsignmentAddStep2 from './components/ConsignmentAddStep2';
import ConsignmentAddStep3 from './components/ConsignmentAddStep3';
import CreateOrSaveConsignment, { createOrSaveConsignmentMutation } from './mutations/createOrSaveConsignment';

const query = graphql`
    query ConsignmentAddQuery($envdAccountId: String!) {
        viewer {
            forms(envdAccountId: $envdAccountId) {
                name
                displayName
                species
                logoUrl
            }
        }
    }
`;

const ConsignmentAddPage: React.FC<{ forms: any[] }> = ({ forms }) => {
    const [mutate] = useMutation(createOrSaveConsignmentMutation);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [{ user }] = useUserState();
    const history = useHistory();

    const [, setWarningMessage] = useContext(WarningsContext);

    const [currentStep, setCurrentStep] = useState<1 | 2 | 3>(1);

    const [state, setState] = useState<{
        ownedByUser: boolean;
        owner: IPerson | null;
        consignor: IPerson | null;
        sameConsigneeAsDestination: boolean;
        destination: IPerson | null;
        consignee: IPerson | null;
        movementDate: Date | null;
        movementTime: string | null;
        species: ConsignmentSpecies;
        forms: SelectableListItem[];
    }>({
        consignor: user ? mapUserToPerson(user, 'CONSIGNOR') : null,
        owner: user ? mapUserToPerson(user, 'OWNER') : null,
        ownedByUser: true,
        consignee: null,
        sameConsigneeAsDestination: true,
        destination: null,
        movementDate: null,
        movementTime: null,
        species: ConsignmentSpecies.CATTLE,
        forms: (forms ?? [])
            .map((x: any) => getFormAlias(x.name))
            .coalesce()
            .map((x: IFormAlias) => ({
                id: x.program,
                title: x.alias,
                subtitle: x.subtitle,
                image: x.image,
                exclusive: x.program.startsWith('NFAS') ? ALL_NFAS_FORMS.filter((nfas) => x.program !== nfas.program).map((nfas) => nfas.program) : [],
            })),
    });

    if (isLoading) {
        return <Loader isLoading={true} error={false} retry={() => null} timedOut={false} pastDelay={false} />;
    }

    return (
        <>
            <FeedbackButton url={Config.ISC_CUSTOMERFEEDBACK_URL} />

            {currentStep === 1 ? (
                <ConsignmentAddStep1 title={<h1>Movement information</h1>} state={state} setState={setState as any} onSubmit={() => setCurrentStep(2)} />
            ) : currentStep === 2 ? (
                <ConsignmentAddStep2 state={state} setState={setState as any} forms={forms} onPrevious={() => setCurrentStep(1)} onSubmit={() => setCurrentStep(3)} />
            ) : (
                <ConsignmentAddStep3
                    state={state}
                    setState={setState as any}
                    onPrevious={() => setCurrentStep(2)}
                    onSubmit={async (selectedForms: SelectableListItem[]) => {
                        try {
                            const picTo = state.destination;
                            if (picTo) {
                                setIsLoading(true);
                                const envdAccountId = user?.accountDetails?.id;
                                try {
                                    const consignment = await CreateOrSaveConsignment(
                                        mutate,
                                        {
                                            forms: selectedForms
                                                .filter((form) => form.selected)
                                                .map((form) => form.id)
                                                .map(mapProgramToForm) as any,

                                            origin: state.consignor ? mapPersonToEntity(state.consignor) : undefined,
                                            destination: state.destination ? mapPersonToEntity(state.destination) : undefined,
                                            owner: state.owner ? mapPersonToEntity(state.owner) : undefined,
                                            consignee: state.consignee ? mapPersonToEntity(state.consignee) : undefined,

                                            movementDate: state.movementDate?.toISODateString(),
                                            movementTime: state.movementTime,

                                            // TODO: Should we include Declaration / Transporters?
                                            answers: [],
                                        },
                                        envdAccountId
                                    );
                                    history.push(`/consignments/edit/${consignment.number}/${SectionName.LIVESTOCK_DESCRIPTION}`);
                                } catch (e) {
                                    MLALogger.Log(['ConsignmentAdd'], e, 'error');
                                }
                            }
                        } catch (error) {
                            MLALogger.Log(['ConsignmentAdd'], error, 'error');
                            setWarningMessage(true);
                            setIsLoading(false);
                        }
                    }}
                />
            )}
        </>
    );
};

const ConsignmentAdd: React.FC = () => {
    const [{ user }] = useUserState();
    const envdAccountId = user?.accountDetails?.id;
    const { data } = useQuery<ConsignmentAddQuery>(query, { envdAccountId }, { fetchPolicy: 'network-only' });

    if (data) {
        const forms = data.viewer?.forms || [];
        const activeForms = forms.filter((f) => !ALL_DEPRECATED_FORMS.some((df) => df.program === f.name));
        // Schema typings suck
        return <ConsignmentAddPage forms={activeForms as any[]} />;
    } else {
        return <Loader error={''} isLoading pastDelay={false} timedOut={false} retry={() => null} />;
    }
};

export default ConsignmentAdd;
